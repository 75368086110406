<template>
  <div
    class="modal fade"
    :id="'errorModal_' + error.id"
    tabindex="-1"
    :aria-labelledby="'errorModalLabel_' + error.id"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" style="max-width: 80%">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'errorModalLabel_' + error.id">
            Tracking Get Jobs - Id: {{ error.id }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="error-content text-primary text-start mb-0" style="white-space: initial">
            {{ error.job_parsed }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: { error: Object },
};
</script>
