import { requestApi } from "./index";

const api = {
  error: {
    list: async (params = "") => requestApi("/admin2021/tracking/errors" + params),
  },
  ip: {
    list: async (params = "") => requestApi("/admin2021/tracking/ips" + params),
  },
  getJob: {
    list: async (params = "") => requestApi("/admin2021/tracking/get-jobs" + params),
  },
  paidJob: {
    list: async (params = "") => requestApi("/admin2021/tracking/paid-jobs" + params),
  },
};

export default api;
