<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Tracking Get Jobs</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Tracking Get Jobs</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card p-4 flex-column flex-md-row">
            <slot name="user-total"></slot>
            <h6 class="mb-0">
              Tỉ lệ ra jobs :
              <span class="badge bg-success font-size-14">{{ tracking }}</span>
            </h6>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Username</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập username "
                      v-model="username"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Uid</label>
                    <input type="text" class="form-control" placeholder="nhập uid " v-model="uid" />
                  </div>
                </form>
                <div class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Os</label>
                    <select v-model="os" class="form-select">
                      <option value="">Tất cả</option>
                      <option value="pc">PC</option>
                      <option value="mobile">Mobile</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Provider</label>
                    <select v-model="provider" class="form-select">
                      <option value="">Tất cả</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                    </select>
                  </div>
                </div>
                <form class="col-md-3">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Tracking Get Jobs'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button
                class="btn btn-success font-10 py-1 btn-xs"
                data-bs-toggle="modal"
                :data-bs-target="'#errorModal_' + props.row.id"
              >
                Chi tiết
              </button>
              <error-modal-vue :error="props.row" />
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import trackingApi from "@/api/tracking";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";
import ErrorModalVue from "./ErrorModal.vue";

export default {
  name: "TrackingGetJobs",
  components: {
    Datatable,
    LoadingVue,
    ErrorModalVue,
  },
  data() {
    return {
      username: null,
      os: "",
      provider: "",
      uid: "",
      limit: 50,
      logs: [],
      loading: false,
      tracking: "",
      columns: [
        { label: "Id", field: "id" },
        { label: "User Id", field: "user_id" },
        { label: "Username", field: "username" },
        { label: "Uid", field: "uid" },
        { label: "Count", field: "count" },
        { label: "Os", field: "os" },
        { label: "Provider", field: "provider" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
        { label: "Ngày cập nhật", field: "updated_at", timeago: true },
      ],
    };
  },
  watch: {
    os() {
      this.getLogsList();
    },
    provider() {
      this.getLogsList();
    },
  },
  computed: {
    manipulatedLogs() {
      return this.logs.map((log) => {
        log.job_parsed = JSON.stringify(log.jobs);
        return log;
      });
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
        username: this.username,
        os: this.os,
        uid: this.uid,
        provider: this.provider,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await trackingApi.getJob.list(queries);
      this.loading = false;
      if (response?.success) {
        this.logs = response?.data;
        this.tracking = response?.tracking || "";
      } else {
        this.$toastr.error(response?.message || "Load tracking get jobs không thành công");
        this.logs = [];
      }
    },
  },
};
</script>
